<script lang="ts">
  import { getContext } from "svelte";
  import { derived } from "svelte/store";
  import { fade } from "svelte/transition";
  import type { CreateQueryResult } from "@tanstack/svelte-query";

  import { VERSION } from "../../lib/about";
  import {
    localLoadValue,
    localStoreValue,
    sessionLoadValue,
    sessionStoreValue,
  } from "../../lib/local-persistence";

  const messages = getContext("useMessages") as CreateQueryResult<Message[]>;
  const seenMessages = getContext("useSeenMessages") as CreateQueryResult<
    number[]
  >;
  const unseenMessages = derived(
    [messages, seenMessages],
    ([messages, seenMessages]) => {
      if (messages.isSuccess && seenMessages.isSuccess) {
        const now = new Date();
        return messages.data.filter((message) => {
          if (seenMessages.data.indexOf(message.id) < 0) {
            if (message.notify_start !== null) {
              const notifyStart = new Date(message.notify_start);
              if (notifyStart <= now) {
                if (message.notify_end !== null) {
                  const notifyEnd = new Date(message.notify_end);
                  if (notifyEnd > now) {
                    return true;
                  }
                } else {
                  return true;
                }
              }
            }
          }
          return false;
        });
      }
      return [];
    },
  );
  const apiStatus = getContext("useAPIStatus") as CreateQueryResult<APIStatus>;

  let sessionRefreshToken = sessionLoadValue(
    "auth.refresh_token",
    "session-not-set",
  );
  let localRefreshToken = localLoadValue("auth.refresh_token", "local-not-set");
  let asked = sessionLoadValue("auth.remember_me_asked", false);

  function doNotRememberMe() {
    asked = true;
    sessionStoreValue("auth.remember_me_asked", true);
  }

  function rememberMe() {
    asked = true;
    localStoreValue(
      "auth.refresh_token",
      sessionLoadValue("auth.refresh_token", ""),
    );
  }
</script>

<aside
  class="fixed w-screen md:w-auto bottom-0 left-0 md:bottom-auto md:top-0 md:mt-2 md:ml-2 z-20 overflow-y-auto max-h-[100vh]"
>
  {#if !asked && sessionRefreshToken !== "session-not-set" && sessionRefreshToken !== localRefreshToken}
    <div
      transition:fade
      class="md:w-72 border border-neutral-200 bg-white shadow-lg px-3 pt-2 pb-4 mb-4"
      aria-live="assertive"
    >
      <h3 class="text-lg font-bold text-primary-blue mb-2">Remember me</h3>
      <p class="mb-3">
        Remember this login for the next 14 days. <span class="text-error"
          >Do not select this if you are on a shared system.</span
        >
      </p>
      <div class="flex flex-row space-x-4 justify-around">
        <button on:click={doNotRememberMe} data-ocl-button="">No</button>
        <button on:click={rememberMe} data-ocl-button="">Yes</button>
      </div>
    </div>
  {/if}

  {#if $unseenMessages.length > 0}
    <div
      transition:fade
      class="md:w-72 border border-neutral-200 bg-white shadow-lg px-3 py-2 mb-4"
      aria-live="polite"
    >
      <h3 class="text-lg font-bold text-primary-blue mb-2">
        New Notifications
      </h3>
      <ul>
        {#each $unseenMessages as popupMessage}
          <li>
            <a
              href="#/messages/{popupMessage.id}"
              class="text-primary hover:text-primary-700 underline"
              >{popupMessage.title}</a
            >
          </li>
        {/each}
      </ul>
    </div>
  {/if}

  {#if $apiStatus.isSuccess && $apiStatus.data.version !== VERSION}
    <div
      transition:fade
      class="md:w-72 border border-neutral-200 bg-white shadow-lg px-3 pt-2 pb-4 mb-4"
    >
      <h3 class="text-lg font-bold text-primary-blue mb-2">
        Interface Update Required
      </h3>
      <p class="mb-3">
        The interface has been updated and you need to reload the interface to
        get the latest version. Please save your work and then click on the
        button below to reload.
      </p>
      <a href="/" data-ocl-button="">Click here to update.</a>
    </div>
  {/if}
</aside>
