<script lang="ts">
  import LoadingPlaceholder from "../generic/LoadingPlaceholder.svelte";
</script>

<h1 class="sr-only" aria-live="assertive"><slot></slot></h1>
<main>
  <div class="flex flex-row space-x-8 items-center" aria-hidden="true">
    <span class="w-20 h-20 rounded-full overflow-hidden">
      <LoadingPlaceholder />
    </span>
    <span class="flex-1 h-8">
      <LoadingPlaceholder />
    </span>
  </div>
</main>
