<script lang="ts">
  import { mdiArrowRightThick } from "@mdi/js";
  import { getContext, onDestroy } from "svelte";
  import { derived } from "svelte/store";
  import {
    createQuery,
    useQueryClient,
    type CreateQueryResult,
  } from "@tanstack/svelte-query";

  import { fetchRequest, mutationRequest } from "../lib/api";
  import { sessionLoadValue } from "../lib/local-persistence";
  import { sleep } from "../lib/util";
  import ComputerImage from "../assets/Computer.svg";
  import LoadingPlaceholder from "../components/generic/LoadingPlaceholder.svelte";
  import LaunchStatusEntry from "../components/ui/LaunchStatusEntry.svelte";
  import PageHeading from "../components/generic/PageHeading.svelte";
  import Icon from "../components/generic/Icon.svelte";
  import { Dialog } from "bits-ui";
  import { fade } from "svelte/transition";

  const vces = createQuery({
    queryKey: ["/vces"],
    queryFn: fetchRequest<VCE[]>,
    refetchInterval: 60000,
  });
  const groupedVCEs = derived(vces, (vces) => {
    const groups: VCE[][] = [];
    if (vces.isSuccess) {
      for (let vce of vces.data) {
        if (
          groups.length === 0 ||
          vce.presentation !== groups[groups.length - 1][0].presentation
        ) {
          groups.push([]);
        }
        groups[groups.length - 1].push(vce);
      }
    }
    return groups;
  });

  const queryClient = useQueryClient();
  const apiStatus = getContext("useAPIStatus") as CreateQueryResult<APIStatus>;
  const user = getContext("useUser") as CreateQueryResult<User>;
  const vce = getContext("useVce") as CreateQueryResult<VCE>;
  const config = getContext("useConfig") as CreateQueryResult<Config>;

  let launching = false;
  let launchProgress = [] as any;
  let launchFailed = false;
  let shuttingDown = false;

  async function launch(vce: VCE) {
    if ($user.isSuccess) {
      shuttingDown = false;
      launching = true;
      launchFailed = false;
      launchProgress = [
        {
          id: "starting",
          label: "Starting the VCE",
          message: "Your VCE has been requested",
          state: "busy",
        },
      ];
      try {
        await Promise.all([
          mutationRequest(
            ["/users", "/" + $user.data.id, "/vce"],
            "POST",
            { id: vce.id },
            true,
          ),
          trackLaunch(vce),
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  }

  function trackLaunch(vce: VCE) {
    return new Promise<void>((resolve) => {
      let connection: WebSocket;
      if (window.location.protocol === "https:") {
        connection = new WebSocket(
          "wss://" +
            window.location.hostname +
            ":" +
            window.location.port +
            "/api/vces/" +
            vce.id +
            "/track-launch",
        );
      } else {
        connection = new WebSocket(
          "ws://" +
            window.location.hostname +
            ":" +
            window.location.port +
            "/api/vces/" +
            vce.id +
            "/track-launch",
        );
      }
      connection.addEventListener("open", () => {
        connection.send(
          JSON.stringify({
            type: "auth",
            token: sessionLoadValue("auth.access_token", ""),
          }),
        );
      });
      connection.addEventListener("close", () => {
        if ($user.isSuccess) {
          queryClient.invalidateQueries({
            queryKey: ["/users", "/" + $user.data.id, "/vce"],
          });
        }
        resolve();
      });
      connection.addEventListener("message", (msg) => {
        const update = JSON.parse(msg.data);
        if (update.id) {
          let found = false;
          for (let idx = 0; idx < launchProgress.length; idx++) {
            const progress = launchProgress[idx];
            if (progress.id === update.id) {
              launchProgress[idx] = update;
              found = true;
              break;
            }
          }
          if (!found) {
            launchProgress.push(update);
          }
          if (update.id === "starting" && update.status === "failed") {
            launchFailed = true;
            for (let idx = 0; idx < launchProgress.length; idx++) {
              console.log(launchProgress[idx]);
              if (launchProgress[idx].status === "active") {
                launchProgress[idx].status = "failed";
              }
            }
          }
          launchProgress = launchProgress;
        }
      });
    });
  }

  async function shutdownVCE() {
    if ($user.isSuccess && $vce.isSuccess) {
      shuttingDown = true;
      launching = false;
      try {
        await mutationRequest(
          ["/users", "/" + $user.data.id, "/vce"],
          "DELETE",
          { id: $vce.data.id },
          true,
        );
        for (let timeout of [
          500, 500, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 1000, 2000,
          2000, 2000, 4000, 5000, 5000, 10000, 20000,
        ]) {
          if ($vce.isSuccess && $vce.data === null) {
            break;
          }
          queryClient.invalidateQueries({
            queryKey: ["/users", "/" + $user.data.id, "/vce"],
          });
          await sleep(timeout);
        }
      } catch (e) {
        console.error(e);
      }
      shuttingDown = false;
    }
  }

  const vceUnsubscribe = vce.subscribe((vce) => {
    if ($vce.isSuccess && $vce.data && $vce.data.ready) {
      launching = false;
    }
  });

  onDestroy(vceUnsubscribe);
</script>

<main>
  {#if !$vce.isSuccess || !$vce.data || !$vce.data.ready}
    <PageHeading>
      <img slot="image" src={ComputerImage} alt="" aria-hidden="true" />
      <span>Virtual Computing Environments</span>
    </PageHeading>
    <ul class="grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-4 pt-4 pb-8">
      {#if $vces.isPending}
        <li class="h-24"><LoadingPlaceholder /></li>
        <li class="h-24"><LoadingPlaceholder /></li>
        <li class="h-24"><LoadingPlaceholder /></li>
      {:else if $vces.isSuccess}
        {#each $groupedVCEs as group}
          <li
            class="md:col-span-3 mt-8 first:mt-0 text-lg text-primary-blue font-bold"
          >
            {group[0].presentation}
          </li>
          {#each group as vce}
            <li>
              {#if $apiStatus.isSuccess && $apiStatus.data.hosts[vce.host] && $apiStatus.data.hosts[vce.host].ready}
                <button
                  on:click={() => {
                    launch(vce);
                  }}
                  class="block p-4 w-full border border-primary-blue-150 shadow hover:bg-primary-blue-50 focus:bg-secondary-yellow transition-colors"
                  title="{vce.title} ({vce.presentation})"
                >
                  <span
                    class="flex flex-row space-x-2 items-center mb-4 w-full font-bold bg-primary-500"
                  >
                    <span
                      class="flex-1 block text-primary-blue truncate text-left"
                      >{vce.title}</span
                    >
                    <Icon
                      path={mdiArrowRightThick}
                      class="block w-6 h-6 text-secondary-orange"
                    />
                  </span>
                  <span class="block w-full flex-1 text-left"
                    >{vce.description}</span
                  >
                </button>
              {:else}
                <span
                  class="block p-4 w-full border border-primary-blue-150 shadow"
                  title="The {vce.title} ({vce.presentation}) is currently not available, as its host cannot be reached."
                >
                  <span
                    class="block mb-4 w-full font-bold text-primary-blue truncate text-left"
                    >{vce.title}</span
                  >
                  <span class="block mb-4 w-full text-error font-bold text-sm"
                    >This VCE is currently not available.</span
                  >
                  <span class="block w-full flex-1 text-left"
                    >{vce.description}</span
                  >
                </span>
              {/if}
            </li>
          {/each}
        {/each}
      {/if}
    </ul>

    <Dialog.Root
      open={launching}
      closeOnEscape={false}
      closeOnOutsideClick={false}
    >
      <Dialog.Trigger class="hidden" />
      <Dialog.Portal data-ocl-dialog-blocking="">
        <Dialog.Overlay transition={fade} />
        <Dialog.Content>
          <Dialog.Title>Your VCE is launching. Please wait...</Dialog.Title>
          <div data-ocl-dialog-content="">
            <ul>
              {#each launchProgress as progress}
                <LaunchStatusEntry
                  label={progress.label}
                  message={progress.message}
                  status={progress.status}
                />
              {/each}
              {#if launchFailed}
                <p class="my-4">
                  The VCE failed to launch. This is generally a transient issue
                  and you should try again in a few minutes.
                </p>
                <p class="mt-4 text-right">
                  <button
                    on:click={() => {
                      launching = false;
                    }}
                    data-ocl-button>Close</button
                  >
                </p>
              {/if}
            </ul>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  {:else}
    <PageHeading>
      <img slot="image" src={ComputerImage} alt="" aria-hidden="true" />
      <span>{$vce.data.title} ({$vce.data.presentation})</span>
    </PageHeading>
    <p>
      Your Virtual Computing Environment (VCE) is now ready and you can access
      it by clicking on the link below. Before that the {$config.data?.title} is
      required by law to show you the following two pieces of information about accessibility
      and cookies in the VCE.
    </p>
    <div class="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-8 pt-4 pb-8">
      <div class="md:order-2">
        <section class="pb-8">
          <h2 class="text-xl font-bold text-primary-blue flex mb-4">
            Cookie Policy
          </h2>
          <p class="pb-2">
            The system used by the {$config.data?.title} to provide access to the
            VCEs sets a range of cookies that contain authentication information
            and which are strictly necessary.
          </p>
          <p>
            You can find <a
              class="underline text-primary-blue"
              href="https://about.open.ac.uk/strategy-and-policies/policies-and-statements/cookie-use-ou-website"
              target="_blank"
              >further details on the Open University's Cookie Policy here</a
            >.
          </p>
        </section>
        <section>
          <h2 class="text-xl font-bold text-primary-blue mb-4">
            Accessibility Information
          </h2>
          <p>
            While the {$config.data?.title} is
            <a
              href="https://docs.ocl.open.ac.uk/container-launcher/user/accessibility-statement.html"
              target="_blank">fully accessible</a
            >, the accessibility of the VCEs that it provides access to varies.
            For full details on the accessibility limitations of your VCE,
            please consult your module's Accessibility Guide, which contains
            detailed information on the limitations and also on the support
            available from the module and the Open University.
          </p>
        </section>
      </div>
      <section class="md:order-1 pb-8">
        <h2 class="text-xl font-bold text-primary-blue flex mb-4">
          Access the Virtual Computing Environment
        </h2>
        <p>
          By accessing your Virtual Computing Environment you accept the Cookie
          Policy and Accessibility Information as detailed <span
            class="md:hidden">above</span
          ><span class="hidden md:inline">on the right</span>.
        </p>
        <div class="flex flex-row justify-around pt-8">
          <a
            href={$vce.data.url}
            target="_blank"
            data-ocl-button-large=""
            class="w-48 text-center">Access the VCE</a
          >
          <button on:click={shutdownVCE} data-ocl-button-large="" class="w-48"
            >Stop the VCE</button
          >
        </div>
      </section>
    </div>

    <Dialog.Root
      open={shuttingDown}
      closeOnEscape={false}
      closeOnOutsideClick={false}
    >
      <Dialog.Trigger class="hidden" />
      <Dialog.Portal data-ocl-dialog-blocking="">
        <Dialog.Overlay transition={fade} />
        <Dialog.Content>
          <Dialog.Title>VCE Shutting down</Dialog.Title>
          <ul data-ocl-dialog-content="">
            <LaunchStatusEntry
              label="Shutting down the VCE"
              status={$vce.isSuccess && $vce.data !== null ? "busy" : "success"}
              message="Shutdown of the VCE has been requested"
            />
          </ul>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  {/if}
</main>
