<script lang="ts">
  import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";

  import {
    localDeleteValue,
    sessionDeleteValue,
    sessionStoreValue,
  } from "./lib/local-persistence";

  import App from "./App.svelte";

  const queryClient = new QueryClient();

  const params = new URLSearchParams(window.location.search);
  if (params.has("refresh_token")) {
    const refreshToken = params.get("refresh_token");
    sessionStoreValue("auth.refresh_token", refreshToken);
    sessionDeleteValue("auth.access_token");
    localDeleteValue("auth.access_token");
    window.history.replaceState(
      null,
      "",
      window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        window.location.hash,
    );
  }
</script>

<QueryClientProvider client={queryClient}>
  <App />
</QueryClientProvider>
