<script lang="ts">
  /*
   * A simple pulsing placeholder for loading indication.
   */
</script>

<span
  class="block group flex flex-col w-full h-full rounded-b overflow-hidden text-left bg-[#d3d4de] animate-pulse"
  >&nbsp;
</span>
