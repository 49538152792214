<script lang="ts">
  /**
   * Helper component to show a Launch status entry.
   */
  export let label: string;
  export let status: string;
  export let message: string;
</script>

<li class="flex space-x-4" title={message}>
  <span class="flex-1">{label}</span>
  <span>
    {#if status === "success"}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="text-success fill-current w-6 h-6"
        ><title>Success</title><path
          d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
        /></svg
      >
    {:else if status === "failed"}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="text-error fill-current w-6 h-6"
        ><title>Error</title><path
          d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z"
        /></svg
      >
    {:else}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="text-primary fill-current animate-spin w-6 h-6"
        ><title>Working</title><path
          d="M19,8L15,12H18A6,6 0 0,1 12,18C11,18 10.03,17.75 9.2,17.3L7.74,18.76C8.97,19.54 10.43,20 12,20A8,8 0 0,0 20,12H23M6,12A6,6 0 0,1 12,6C13,6 13.97,6.25 14.8,6.7L16.26,5.24C15.03,4.46 13.57,4 12,4A8,8 0 0,0 4,12H1L5,16L9,12"
        /></svg
      >
    {/if}
  </span>
</li>
