<!-- ou-header v2.0.0.542 -->
<div class="ou_gtaiframe">
  <noscript
    ><iframe
      src="//www.googletagmanager.com/ns.html?id=GTM-PPS2SH"
      height="0"
      width="0"
      style="display:none;visibility:hidden"
      name="GTM"
    ></iframe></noscript
  >
</div>
<div id="ou-head">
  <div id="ou-header">
    <!--<a
      class="ou-skip"
      href="#int-content"
      id="ou-skip-anchor-tutor-home"
      data-translate="true"
      ><i class="int-icon int-icon-arrow-circle-down"></i>Skip to content
    </a>-->

    <div id="ou-logo">
      <a
        class="ou-logo england"
        href="https://www.open.ac.uk/"
        title="The Open University"
      >
        <img
          src="/ouheaders/gui/OU_Logo_Mobile.svg?2.0.0.542"
          srcset="/ouheaders/gui/OU_Logo_Mobile.svg?2.0.0.542 1x, /ouheaders/gui/OU_Logo_Mobile.svg?2.0.0.542 2x"
          alt="The Open University"
        />
      </a>
    </div>
    <div class="ou-identity">
      <p class="ou-identity-name" data-hj-masked=""></p>
    </div>
    <div class="ou-mobile-menu-toggle-div">
      <a
        href="#"
        class="ou-mobile-menu-toggle icon-up"
        id="ou-mobile-menu-toggle"
        ><img
          src="/ouheaders/gui/Accordion_Chevron_Down.svg?2.0.0.542"
          srcset="/ouheaders/gui/Accordion_Chevron_Down.svg?2.0.0.542 1x, /ouheaders/gui/Accordion_Chevron_Down.svg?2.0.0.542 2x"
          alt="Toggle service links"
          title="Toggle service links"
        /></a
      >
    </div>

    <div id="ou-header-nav">
      <div id="ou-service-links" aria-label="Your links" role="navigation">
        <div id="ou-identity">
          <p class="ou-identity-name" data-hj-masked=""></p>
          <p id="ou-identity-id" data-hj-masked=""></p>
        </div>
        <ul>
          <li class="ou-role-signin" id="ou-signin1">
            <a
              href="https://msds.open.ac.uk/signon/sams001.aspx"
              id="ou-signin2"
              data-translate="true">Sign in</a
            ><span>|</span>
          </li>
          <li class="ou-role-signout ou-header-remove" id="ou-signout">
            <a
              href="https://msds.open.ac.uk/signon/samsoff.aspx"
              id="ou-signout2"
              data-translate="true">Sign out</a
            ><span>|</span>
          </li>

          <li id="ou-tutorhome" class="ou-header-remove">
            <a href="https://msds.open.ac.uk/tutorhome/" data-translate="true"
              >TutorHome</a
            >
            <span>|</span>
          </li>

          <li id="ou-intranethome" class="ou-header-remove">
            <a
              href="https://intranet.open.ac.uk/oulife-home/"
              data-translate="true">IntranetHome</a
            >
            <span>|</span>
          </li>

          <li id="ou-contact">
            <a
              href="https://www.open.ac.uk/contact"
              data-translate="true"
              class="ou-ia-public"
              id="ou-public-contact">Contact the OU</a
            ><a
              href="https://www2.open.ac.uk/students/help/your-contacts/"
              data-translate="true"
              class="ou-ia-student"
              id="ou-student-contact">Contact the OU</a
            ><a
              href="https://www2.open.ac.uk/tutors/help/who-to-contact/"
              data-translate="true"
              class="ou-ia-tutor"
              id="ou-tutor-contact">Contact the OU</a
            ><span>|</span>
          </li>
          <li>
            <a
              href="https://docs.ocl.open.ac.uk/container-launcher/user/accessibility-statement.html"
              data-translate="true"
              class="ou-display-public-tutor"
              >Accessibility Statement: OpenComputing Lab</a
            ><a
              href="https://docs.ocl.open.ac.uk/container-launcher/user/accessibility-statement.html"
              data-translate="true"
              class="ou-ia-student"
              >Accessibility Statement: OpenComputing Lab</a
            >
          </li>
          <li class="ou-search ou-ia-public">
            <label
              for="ou-header-search-public"
              class="ou-hide"
              data-translate="true">Search the OU</label
            ><input
              type="search"
              id="ou-header-search-public"
              class="ou-header-search"
              name="q"
              data-translate="true"
              placeholder="Search the OU"
              onkeyup="javascript: onSearchBoxInput(event);"
            />
            <div class="ou-button-container">
              <button
                type="button"
                value="Search"
                id="search-button-header"
                onclick="submitSearch('ou-header-search-public');"
                ><img
                  src="/ouheaders/gui/Search_Icon_Magnifier.svg?2.0.0.542"
                  srcset="/ouheaders/gui/Search_Icon_Magnifier.svg?2.0.0.542 1x, /ouheaders/gui/Search_Icon_Magnifier.svg?2.0.0.542 2x"
                  alt="Search"
                  title="Search"
                /></button
              >
            </div>
          </li>
        </ul>
      </div>
      <!-- end ou-service-links -->
      <div class="ou-ia-nav" id="ou-ia-nav" aria-label="Main" role="navigation">
        <ul class="ou-ia-students">
          <li class="ou-ia-studenthome">
            <a href="https://msds.open.ac.uk/students/">StudentHome</a>
          </li>

          <li class="ou-ia-profile">
            <a href="https://msds.open.ac.uk/students/profile.aspx">Profile</a>
          </li>

          <li class="ou-ia-study student">
            <a
              href="https://learn2.open.ac.uk/course/format/ousubject/redirect.php"
              >Study</a
            >
          </li>

          <li class="ou-ia-library">
            <a href="https://www.open.ac.uk/library/">Library</a>
          </li>

          <li class="ou-ia-careers">
            <a href="https://help.open.ac.uk/browse/careers/">Careers</a>
          </li>

          <li class="ou-ia-community">
            <a href="https://community.open.ac.uk/">Community</a>
          </li>

          <li class="ou-ia-helpcentre">
            <a href="https://help.open.ac.uk/">Help Centre</a>
          </li>
        </ul>
      </div>
      <!-- end ou-ia-nav -->
    </div>
    <!--end ou-header-navigation-->
  </div>
</div>
<!-- End ou-header v2.0.0.542 -->
