<script lang="ts">
  /**
   * Skip to the first h2 element
   */
  function skipToContent() {
    const heading = document.querySelector("h1") as HTMLElement | null;
    if (heading) {
      heading.setAttribute("tabindex", "-1");
      heading.focus();
    }
  }
</script>

<button
  on:click={skipToContent}
  class="block flex flex-row items-center space-x-2 absolute focus:fixed left-1/2 top-[-1000px] focus:top-0 transform -translate-x-1/2 z-50 px-3 py-2 mt-2 bg-secondary-yellow border-2 border-black"
>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-hidden="true"
    class="w-4 h-4 fill-current"
    ><path
      d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,17L17,12H14V8H10V12H7L12,17Z"
    /></svg
  >
  <span>Skip to content</span>
</button>
