<script lang="ts">
  import { Dialog } from "bits-ui";
  import { mdiLogout } from "@mdi/js";
  import { onDestroy, getContext, tick } from "svelte";
  import { derived } from "svelte/store";
  import { fade } from "svelte/transition";
  import { createQuery, type CreateQueryResult } from "@tanstack/svelte-query";
  import { removeCookie } from "typescript-cookie";

  import { fetchRequest } from "../lib/api";
  import {
    localDeleteValue,
    sessionDeleteValue,
  } from "../lib/local-persistence";
  import PageHeading from "../components/generic/PageHeading.svelte";
  import Icon from "../components/generic/Icon.svelte";

  const config = getContext("useConfig") as CreateQueryResult<Config>;

  const hosts = createQuery({
    queryKey: ["/hosts"],
    queryFn: fetchRequest<AnynomousHost[]>,
  });

  const logoutReady = derived([config, hosts], ([config, hosts]) => {
    return config.isSuccess && hosts.isSuccess;
  });

  const logoutReadyUnsubscribe = logoutReady.subscribe((ready) => {
    if (ready) {
      tick().then(() => {
        localDeleteValue("auth.refresh_token");
        sessionDeleteValue("auth.refresh_token");
        sessionDeleteValue("auth.access_token");
        sessionDeleteValue("auth.remember_me_asked");
        removeCookie("ocl-auth");
      });
      if ($config.isSuccess && $config.data && $config.data.vle) {
        setTimeout(() => {
          window.location.href = $config.data.vle.url;
        }, 3000);
      }
    }
  });

  onDestroy(logoutReadyUnsubscribe);
</script>

<main>
  <PageHeading>
    <Icon slot="image" path={mdiLogout} class="w-full h-full text-white" />
    <span>Logging out</span>
  </PageHeading>

  <Dialog.Root open={true} closeOnEscape={false} closeOnOutsideClick={false}>
    <Dialog.Trigger class="hidden" />
    <Dialog.Portal data-ocl-dialog-blocking="">
      <Dialog.Overlay transition={fade} />
      <Dialog.Content>
        <Dialog.Title>Logging out...</Dialog.Title>
        <div data-ocl-dialog-content="">
          <p class="mb-2">
            You are being logged out. Please wait while your VCEs are shut down.
          </p>
          {#if $config.isSuccess && $config.data.vle && $config.data.vle.url}
            <p>
              You will automatically be returned to the VLE after the logout is
              complete.
            </p>
          {/if}
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
</main>

{#if $hosts.isSuccess}
  {#each $hosts.data as host}
    <iframe
      src="{host.uri}/hub/logout"
      title="Logging out of {host.label}"
      class="sr-only"
    ></iframe>
  {/each}
{/if}
