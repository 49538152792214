import { type QueryOptions } from "@tanstack/svelte-query";

import { localLoadValue, sessionLoadValue, sessionStoreValue } from "./local-persistence";

export async function fetchRequest<ResponseModel>({ queryKey }: QueryOptions) {
  if (queryKey) {
    let response = await window.fetch("/api" + queryKey.join(""), {
      headers: { "Authorization": "Bearer " + sessionLoadValue("auth.access_token", "") }
    });
    if (response.ok) {
      return (await response.json()) as ResponseModel;
    }
    if (response.status == 403) {
      response = await window.fetch("/auth/refresh", {
        headers: { "Authorization": "Bearer " + sessionLoadValue('auth.refresh_token', localLoadValue('auth.refresh_token', "")) as string }
      });
      if (response.ok) {
        sessionStoreValue("auth.access_token", await response.text());
        response = await window.fetch("/api" + queryKey.join(""), {
          headers: { "Authorization": "Bearer " + sessionLoadValue("auth.access_token", "") }
        });
        if (response.ok) {
          return (await response.json()) as ResponseModel;
        }
      }
    }
  }
  throw new Error("Could not fetch data");
}

class MutationError extends Error {
  constructor(message: string, options: { cause: any }) {
    super(message, options);
  }
}

export async function mutationRequest<ResponseModel>(mutationKey: string[], method: string, data: ResponseModel, singleError: boolean) {
  let response = await window.fetch("/api" + mutationKey.join(""), {
    method: method,
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionLoadValue("auth.access_token", ""),
    },
  });
  if (response.ok) {
    if (response.status === 200) {
      return (await response.json()) as ResponseModel;
    } else {
      return null;
    }
  }
  if (response.status == 403) {
    response = await window.fetch("/auth/refresh", {
      headers: { "Authorization": "Bearer " + sessionLoadValue('auth.refresh_token', localLoadValue('auth.refresh_token', "")) as string }
    });
    if (response.ok) {
      sessionStoreValue("auth.access_token", await response.text());
      response = await window.fetch("/api" + mutationKey.join(""), {
        method: method,
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionLoadValue("auth.access_token", ""),
        },
      });
      if (response.ok) {
        if (response.status === 200) {
          return (await response.json()) as ResponseModel;
        } else {
          return null;
        }
      } else {
        if (singleError) {
          throw new Error(((await response.json()) as OpenApiError).detail.map((detail) => {
            return detail.msg[0].toUpperCase() + detail.msg.substring(1);
          }).join(" "));
        } else {
          const cause = {} as OpenAPIErrorDict;
          for (let detail of ((await response.json()) as OpenApiError).detail) {
            cause[detail.loc[1]] = detail;
          }
          throw new MutationError("Mutation failed.", { cause: cause });
        }
      }
    } else {
      throw new MutationError("You are not authorised for this action.", { cause: undefined })
    }
  } else {
    if (singleError) {
      throw new Error(((await response.json()) as OpenApiError).detail.map((detail) => {
        return detail.msg[0].toUpperCase() + detail.msg.substring(1);
      }).join(" "));
    } else {
      const cause = {} as OpenAPIErrorDict;
      for (let detail of ((await response.json()) as OpenApiError).detail) {
        cause[detail.loc[1]] = detail;
      }
      throw new MutationError("Mutation failed.", { cause: cause });
    }
  }
}
