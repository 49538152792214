<script lang="ts">
  import { getContext } from "svelte";
  import { derived, type Readable } from "svelte/store";
  import { type CreateQueryResult } from "@tanstack/svelte-query";
  import { mdiOpenInNew } from "@mdi/js";

  import { location } from "../../simple-svelte-router";
  import Icon from "../generic/Icon.svelte";

  const config = getContext("useConfig") as CreateQueryResult<Config>;
  const user = getContext("useUser") as CreateQueryResult<User>;
  const vce = getContext("useVce") as CreateQueryResult<CurrentVCE>;
  const messages = getContext("useMessages") as CreateQueryResult<Message[]>;
  const seenMessages = getContext("useSeenMessages") as CreateQueryResult<
    number[]
  >;
  const isAdmin = getContext("useIsAdmin") as Readable<Boolean>;
  const ouMenuOpen = getContext("useOuMenuOpen") as Readable<Boolean>;

  const unseenMessages = derived(
    [messages, seenMessages],
    ([messages, seenMessages]) => {
      if (messages.isSuccess && seenMessages.isSuccess) {
        return messages.data.filter((message) => {
          if (seenMessages.data.indexOf(message.id) < 0) {
            return true;
          }
          return false;
        });
      }
      return [];
    },
  );

  function hideMainMenu() {
    const toggle = document.querySelector("#ou-mobile-menu-toggle");
    if (toggle) {
      (toggle as HTMLAnchorElement).dispatchEvent(new Event("click"));
    }
  }
</script>

<div
  class="{$ouMenuOpen
    ? 'block'
    : 'hidden'} md:block bg-secondary-mid-blue md:bg-primary-blue-50 text-white md:text-black"
>
  <nav
    aria-label="Open Computing Lab"
    class="lg:container lg:mx-auto lg:px-12 flex flex-col md:flex-row lg:space-x-4"
  >
    <a
      href="#/"
      on:click={hideMainMenu}
      class="ocl-main-nav-entry {$location.pathname === '/'
        ? 'ocl-main-nav-entry-current'
        : ''}"
    >
      {#if $config.isSuccess}
        {$config.data.title}
      {:else}
        Loading configuration. Please wait...
      {/if}
    </a>
    <div role="" class="flex-1" />
    {#if $isAdmin}
      <a
        href="#/admin"
        on:click={hideMainMenu}
        class="ocl-main-nav-entry {$location.pathname.startsWith('/admin')
          ? 'ocl-main-nav-entry-current'
          : ''}">Administration</a
      >
    {/if}
    {#if $user.isSuccess}
      <a
        href="#/messages"
        on:click={hideMainMenu}
        class="ocl-main-nav-entry {$location.pathname.startsWith('/messages')
          ? 'ocl-main-nav-entry-current'
          : ''}"
      >
        <span>Notifications</span>
        {#if $unseenMessages.length > 0}
          <span
            class="inline-block bg-secondary-green text-black text-sm px-2 ml-1 rounded-full"
            >{$unseenMessages.length}</span
          >
        {/if}
      </a>
    {/if}
    {#if $user.isSuccess}
      <a
        href="#/user"
        on:click={hideMainMenu}
        class="ocl-main-nav-entry {$location.pathname.startsWith('/user')
          ? 'ocl-main-nav-entry-current'
          : ''}"
        >{$user.data.name}
      </a>
    {/if}
    <a
      href="https://docs.ocl.open.ac.uk/container-launcher/user"
      on:click={hideMainMenu}
      target="_blank"
      class="ocl-main-nav-entry flex flex-row items-center space-x-1"
    >
      <span>Help</span>
      <Icon path={mdiOpenInNew} class="w-4 h-4" />
    </a>
    {#if $user.isSuccess}
      <a
        href="#/logout"
        on:click={hideMainMenu}
        class="ocl-main-nav-entry {$location.pathname === '/logout'
          ? 'ocl-main-nav-entry-current'
          : ''}">Logout</a
      >
    {/if}
  </nav>
</div>
{#if $isAdmin && $location.pathname.startsWith("/admin")}
  <div class="{$ouMenuOpen ? 'block' : 'hidden'} md:block">
    <nav aria-label="Administration" class="border-primary-blue-50 border-b-2">
      <ul
        class="lg:container lg:mx-auto lg:px-12 flex flex-row space-x-6 text-sm"
      >
        <li>
          <a
            href="#/admin/system"
            class="ocl-sub-nav-entry {$location.pathname.startsWith(
              '/admin/system',
            )
              ? 'ocl-sub-nav-entry-current'
              : ''}">System Configuration</a
          >
        </li>
        <li>
          <a
            href="#/admin/users"
            class="ocl-sub-nav-entry {$location.pathname.startsWith(
              '/admin/users',
            )
              ? 'ocl-sub-nav-entry-current'
              : ''}">Users</a
          >
        </li>
        <li>
          <a
            href="#/admin/vces"
            class="ocl-sub-nav-entry {$location.pathname.startsWith(
              '/admin/vces',
            )
              ? 'ocl-sub-nav-entry-current'
              : ''}">VCEs</a
          >
        </li>
        <li>
          <a
            href="#/admin/hosts"
            class="ocl-sub-nav-entry {$location.pathname.startsWith(
              '/admin/hosts',
            )
              ? 'ocl-sub-nav-entry-current'
              : ''}">Hosts</a
          >
        </li>
      </ul>
    </nav>
  </div>
{/if}

<style>
  .ocl-main-nav-entry {
    @apply px-4 py-4 text-sm underline md:no-underline md:font-bold border-t md:border-t-2 border-secondary-mid-blue-350 md:border-transparent hover:underline;
  }
  .ocl-main-nav-entry-current {
    @apply font-bold no-underline md:bg-white md:border-secondary-mid-blue md:text-secondary-mid-blue-450;
  }
  .ocl-sub-nav-entry {
    @apply block px-4 py-4 text-sm border-t-2 border-transparent hover:underline;
  }
  .ocl-sub-nav-entry-current {
    @apply bg-white border-secondary-mid-blue text-secondary-mid-blue-450 font-bold;
  }
</style>
