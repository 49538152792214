/**
 * An awaitable sleep function.
 *
 * @param time The length of time to sleep for in milliseconds.
 * @returns A promise that resolves after at least `time` time
 */
export function sleep(time: number) {
  const promise = new Promise((resolve) => {
    window.setTimeout(resolve, time);
  });
  return promise;
}
